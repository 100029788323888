// See https://tailwindcss.com/docs/configuration for details
const colors = require('./src/config/colors');

const widths = {
  'base': '0px',
  'xs': '480px',
  'sm': '640px',
  'md': '768px',
  'lg': '960px',
  'xl': '1024px',
  'xxl': '1160px',
}

let heights = {}
Object.entries(widths).map(([key, value]) => {
  heights[`v${key}`] = {'raw': `(min-height: ${value})`}
})

const screens = {
  ...widths,
  ...heights
}

module.exports = {
  theme: {
    extend: {
      colors: colors,
      opacity: {
        '10': '0.1',
        '20': '0.2',
        '25': '0.25',
        '50': '0.5',
        '75': '0.75',
      },
      spacing: {
        '72': '18rem',
        '84': '21rem',
        '96': '24rem',
        '1/2': '50%',
        '1/3': '33.3333333%',
        '2/3': '66.6666666%',
        '1/4': '25%',
        '3/4': '75%',
        '1/5': '20%',
        '2/5': '40%',
        '3/5': '60%',
        '4/5': '80%',
        '1/6': '16.6666666%',
        '5/6': '83.3333333%',
        '1/7': '14.2857143%',
        '2/7': '28.5714285%',
        '3/7': '42.85714285%',
        '4/7': '57.14285714%',
        '5/7': '71.42857142%',
        '6/7': '85.71428571%',
        'full': '100%',
      },
      width: {
        '10vw': '10vw',
        '20vw': '20vw',
        '30vw': '30vw',
        '40vw': '40vw',
        '50vw': '50vw',
        '60vw': '60vw',
        '70vw': '70vw',
        '80vw': '80vw',
        '90vw': '90vw',
      },
      height: {
        '10vh': '10vh',
        '20vh': '20vh',
        '30vh': '30vh',
        '40vh': '40vh',
        '50vh': '50vh',
        '60vh': '60vh',
        '70vh': '70vh',
        '80vh': '80vh',
        '90vh': '90vh',
      },
      inset: {
        '1/2': '50%',
        '1/3': '33.3333333%',
        '2/3': '66.6666666%',
        '1/4': '25%',
        '3/4': '75%',
        '1/5': '20%',
        '2/5': '40%',
        '3/5': '60%',
        '4/5': '80%',
        '1/6': '16.6666666%',
        '5/6': '83.3333333%',
        '1/7': '14.2857143%',
        '2/7': '28.5714285%',
        '3/7': '42.85714285%',
        '4/7': '57.14285714%',
        '5/7': '71.42857142%',
        '6/7': '85.71428571%',
        '-1/2': '-50%',
        '-1/3': '-33.3333333%',
        '-2/3': '-66.6666666%',
        '-1/4': '-25%',
        '-3/4': '-75%',
        '-1/5': '-20%',
        '-2/5': '-40%',
        '-3/5': '-60%',
        '-4/5': '-80%',
        '-1/6': '-16.6666666%',
        '-5/6': '-83.3333333%',
        '-1/7': '-14.2857143%',
        '-2/7': '-28.5714285%',
        '-3/7': '-42.85714285%',
        '-4/7': '-57.14285714%',
        '-5/7': '-71.42857142%',
        '-6/7': '-85.71428571%',
      },
      minHeight: {
        '72': '18rem',
        '84': '21rem',
        '96': '24rem',
        '128': '32rem',
        '50vh': '50vh',
        '60vh': '60vh',
        '70vh': '70vh',
        '80vh': '80vh',
        '90vh': '90vh',
      },
      maxHeight: {
        '72': '18rem',
        '84': '21rem',
        '96': '24rem',
        '128': '32rem',
        '50vh': '50vh',
        '60vh': '60vh',
        '70vh': '70vh',
        '80vh': '80vh',
        '90vh': '90vh',
      }
    },
    screens: screens
  },
  variants: {}
};

