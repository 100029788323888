import React, { useState, useEffect, useRef } from 'react'
// import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import MenuIcon from '../../graphics/icons/menu-icon.svg'
import PageLink from '../global/PageLink'
import ContactInfo from '../global/ContactInfo'
import { motion, AnimatePresence } from "framer-motion"

const NavComponent  = ({ className }) => ( 
  <div className={className}>
    <NavLink tw="text-white no-underline" to="/parts">
    Parts Database
    </NavLink>
    <NavLink tw="text-white no-underline" to="/brochure">
      Brochure
    </NavLink>
    <NavLink tw="text-white no-underline" to="/news">
      News
    </NavLink>
  </div>
)
export const NavLink = styled(PageLink)``
export const Nav = styled(NavComponent)``

NavComponent.propTypes = {
  className: PropTypes.string.isRequired,
}


const Overlay = () => (
  <motion.div
    tw="absolute bg-gray-dark top-0 bottom-0 h-full opacity-75 z-40 w-full"
    initial={{ opacity: 0 }}
    animate={{ opacity: 0.75 }}
    exit={{ opacity: 0 }}
  />
)
/* eslint-disable no-unused-vars */
const MenuComponent = ({ className, isScrolled, inHome }) => {
/* eslint-enable no-unused-vars */
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const ref = useRef();

  useEffect(() => {
    if (menuIsOpen) {
      document.documentElement.style.cssText = `overflow: hidden; height: 100%`;
      document.body.classList.add('menu-active');
    } else {
      document.documentElement.style.cssText = '';
      document.body.classList.remove('menu-active');
    }
  });

  const handleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  }

  useOnClickOutside(ref, () => {
    setMenuIsOpen(false);
  });

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = event => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
  
          handler(event);
        };
  
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
  
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
      [ref, handler]
    );
  }

  return (
    <>
      <AnimatePresence>
        {menuIsOpen &&
          <Overlay />
        }
      </AnimatePresence>
      <div className={className} tw="top-0 right-0" ref={ref}>
        <div className="menu-icon" onClick={handleMenu} tw="cursor-pointer fixed z-10 top-0 right-0 z-50">
          <MenuIcon tw="block" />
        </div>
        <AnimatePresence>
          {menuIsOpen &&
          <motion.div 
            initial={{ opacity: 0, right: '-300px' }}
            animate={{ opacity: 1, right: 0 }}
            exit={{ opacity: 0, right: '-300px' }}
            tw="bg-gray-dark z-40 pt-4 px-8 fixed w-72 h-full top-0 right-0">
            <Nav />
            <ContactInfo />
          </motion.div>
          }
        </AnimatePresence>
      </div>
    </>
  )
}

MenuComponent.propTypes = {
  className: PropTypes.string.isRequired,
  isScrolled: PropTypes.bool.isRequired,
  inHome: PropTypes.bool.isRequired,
}

const Menu = styled(MenuComponent)`
  ${tw``}
  .menu-icon {
    transition: all ease-in-out 0.2s;
    ${props => props.isScrolled ? tw`pt-2 pr-3` : props.inHome ? tw`p-3 m-2` : tw`p-2 m-2`}
  }
  ${Nav} ${NavLink} {
    ${tw`block py-2`}
  }
  ${ContactInfo} > li {
    ${tw`my-5`}
  }
`

export default Menu
