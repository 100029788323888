import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'

import Logo from '../../graphics/logo.svg'
import ContactInfo from '../global/ContactInfo'
import Menu, { Nav, NavLink } from '../global/Menu'
import Container, { Col } from '../global/Container'
import PageLink from '../global/PageLink'


const HeaderComponent = ({ className }) => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }
    document.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [scrolled])

  return (
    <Location>
      {({ location }) => {
        const inHome =
          location.pathname == '/' ||
          location.hash.includes('pages/entries/index')
            ? true
            : false
        const inAdmin = location.pathname.includes('admin') ? true : false
        return (
          <>
            <header
              className={`${className} ${scrolled ? '-scrolled' : ''}  ${
                inHome ? '-home' : ''
              } ${inAdmin ? '-admin' : ''}`}
            >
              <Container full>
                  <Col tw="flex relative justify-between items-center">
                    <nav tw="flex items-center w-full lg:w-auto justify-between" role="navigation" aria-label="main-navigation">
                      <PageLink className="logo" tw="h-6 mr-5 xxl:mr-10 leading-none block" to="/">
                        <Logo />
                      </PageLink>

                      <div tw="hidden sm:inline">
                        <Nav />
                      </div>

                    </nav>
                    <ContactInfo />
                  </Col>
              </Container>
            </header>
            <Menu isScrolled={scrolled} inHome={inHome} tw="block sm:hidden" />
            <HeaderSpacer
              className={`header-spacer ${inHome ? '-home' : ''}`}
            />
          </>
        )
      }}
    </Location>
  )
}

HeaderComponent.propTypes = {
  className: PropTypes.string.isRequired,
}

const HeaderSpacer = styled.div`
  height: 86px;
  &.-home {
    height: 0;
  }
`

const Header = styled(HeaderComponent)`
  transition: all ease-in-out 0.2s;
  ${Nav} > ${NavLink} {
    ${tw`px-3 xxl:px-5`}
  }
  ${ContactInfo} {
    ${tw`hidden lg:flex`}
    > li:last-child {
      ${tw`pl-4 xxl:pl-8`}
    }
  }
  .logo {
    transition: all ease-in-out 0.2s;
    @media (max-width: 400px) {
      height: 20px;
      width: auto;
    }
    svg {
      height: 100%;
      width: auto;
    }
  }
  &:not(.-home) {
    ${tw`bg-techna-blue py-4 xxl:py-6`};
  }
  &.-home {
    ${tw`py-6 md:py-12`};
  }
  &:not(.-admin) {
    ${tw`fixed top-0 z-20 w-full`}
  }
  &.-admin {
    ${tw`absolute top-0 z-20 w-full`}
  }
  &.-scrolled.-home,
  &.-scrolled {
    ${tw`bg-techna-blue pt-3 pb-3`}
    .logo {
      height: 20px;
    }
    .nav-link {
      ${tw`text-sm`}
    }
  }
`

export default Header
