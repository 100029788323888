import React from 'react'
import Container, { Col } from '../global/Container'
import PropTypes from 'prop-types'
import PageLink from '../global/PageLink'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import colors from '../../config/colors'
import Logo from '../../graphics/logo.svg'
import Facebook from '../../graphics/social/facebook.svg'
// import ReactFlagsSelect from 'react-flags-select';
// import 'react-flags-select/css/react-flags-select.css';
// import { LanguageSelector } from 'gatsby-plugin-translate'


const FooterComponent = ({ className }) => {
  return (
    <footer className={className} tw="py-8 lg:py-16">
        <Container>
          <Col tw="w-full md:w-2/7">
            <PageLink className="logo" to="/">
              <Logo />
            </PageLink>
          </Col>
          <Col tw="w-full md:w-5/7">
            <nav role="navigation" aria-label="main-navigation">
              <PageLink className="nav-link" to="/contact">
                Contact Us
              </PageLink>
              <PageLink className="nav-link" to="/parts">
                Parts Database
              </PageLink>
              <PageLink className="nav-link" to="/brochure">
                Brochure
              </PageLink>
              <PageLink className="nav-link" to="/news">
                News
              </PageLink>
              <a className="nav-link" href="https://www.facebook.com/technandt/">
                <Facebook />
              </a>
            </nav>
          </Col>
          <Col tw="w-1/2">
            <div tw="hidden md:flex text-sm pt-4">
              <span tw="pr-12">
                6707 S. 216th St.<br />
                Kent, WA  98032<br />
              </span>
              <span>
                Tel: <a href="tel:2538722415">(253) 872-2415</a><br />
                Fax: <a href="fax:+12538722416">(253) 872-2416</a><br />
              </span>
            </div>
          </Col>
        </Container>
    </footer>
  )
}

FooterComponent.propTypes = {
  className: PropTypes.string.isRequired,
}

const Footer = styled(FooterComponent)`
  ${tw`bg-gray-light`}
  .logo svg {
    width: 150px;
    path {
      fill: ${colors['techna-blue']}
    }
  }
  nav {
    ${tw`md:flex md:float-right items-center `}
  }
  .nav-link {
    ${tw`block mr-5 md:ml-5 md:mr-0 md:mt-5 mt-3 md:mt-0 text-sm md:h-16`}
  }
  .flag-select__option__icon {
    margin-bottom: 0;
  }
  .flag-select {
    ${tw`mt-8 md:mt-0 border md:float-right border-solid border-gray-400 pb-0 rounded`}
  }
`

export default Footer
