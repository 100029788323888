import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'

import PageLink from '../global/PageLink'
import EmailIcon from '../../graphics/icons/email-icon.svg'
import PhoneIcon from '../../graphics/icons/phone-icon.svg'

const ContactInfoComponent = ({ className }) => (
  <ul className={className} tw="list-none m-0 p-0 text-white">
    <li tw="flex items-center my-0 pl-0">
      <EmailIcon tw="opacity-50" />
      <ul tw="list-none my-0 ml-2 p-0 text-white">
        <li tw="m-0 text-xs">
          <a tw="text-white underline" href="mailto:sales@technandt.com">sales@technandt.com</a>
        </li>
        <li tw="m-0 text-xs">
          <PageLink tw="text-white underline" to="/contact">Contact Form</PageLink>
        </li>
      </ul>
    </li>
    <li tw="flex items-center my-0">
      <PhoneIcon tw="opacity-50" />
      <ul tw="list-none my-0 ml-2 p-0 text-white">
        <li tw="m-0 text-xs">
          Tel:&nbsp;
          <a tw="text-white underline" title="Telephone" href="tel:2538722415">(253) 872-2415</a>
        </li>
        <li tw="m-0 text-xs">
          Fax:&nbsp;
          <a tw="text-white underline" href="fax:+12538722416" title="Fax">
            (253) 872-2416
          </a>
        </li>
      </ul>
    </li>
  </ul>
)

ContactInfoComponent.propTypes = {
  className: PropTypes.string.isRequired,
}

const ContactInfo = styled(ContactInfoComponent)`
  ${tw ``}
`

export default ContactInfo
