import React, { useEffect, useState } from 'react'
const tailwind = require('../../../tailwind.config')
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'

const BreakpointsComponent = ({ className }) => {
  const screens = tailwind.theme.screens
  const [screenWidth, setScreenWidth] = useState(null)
  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize, false)
    return function cleanup() {
      window.removeEventListener('resize', handleResize, false)
    }
  })

  return (
    <div className={className} tw="flex w-full fixed bottom-0 border-0 border-t border-solid border-gray-400">
      <div tw="w-1/3 md:w-1/7 bg-gray-300 font-bold text-xs text-center py-1">{screenWidth}</div>
      {Object.entries(screens)
        .filter(([key, ]) => !['v', 'base'].some(item => key.includes(item)))
        .map(([key, value]) => {
          const width = value.replace('px','')
          return (
            <div tw="w-1/3 md:w-1/7 text-center text-xs py-1 text-gray-500 bg-white" key={key} className={`${screenWidth < 640 && width > 640 ? 'hidden ' : ''}${screenWidth > width ? 'active' : ''}`}>
              {key}: {width}
            </div>
          )
        })
      }
    </div>
  )
}

BreakpointsComponent.propTypes = {
  className: PropTypes.string.isRequired,
}

const Breakpoints = styled(BreakpointsComponent)`
  .hidden {
    ${tw`hidden`}
  }
  .active {
    ${tw`bg-gray-300 text-black`}
  }
`

export default Breakpoints
