import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import appleTouchIcon from '../images/apple-touch-icon.png'
import favicon32 from '../images/favicon-32x32.png'
import favicon16 from '../images/favicon-16x16.png'
import safariTab from '../images/safari-pinned-tab.svg'
import ogImage from '../images/og-image.jpg'

import querySiteMetadata from '../staticQueries/querySiteMetadata'

import Footer from '../components/global/Footer'
import Header from '../components/global/Header'
import GlobalCss from '../config/GlobalCss'
import Breakpoints from '../components/global/Breakpoints'

const TemplateWrapper = ({ children }) => {
  const { title, description } = querySiteMetadata()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
        <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />

        <link rel="mask-icon" href={safariTab} color="#ff4400" />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <GlobalCss />
      <Header />
      {children}
      <Footer />
      {process.env.ENV == 'development' &&
        <Breakpoints />
      }
    </>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TemplateWrapper
