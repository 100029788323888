import React from 'react'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'
import { TranslateLink } from 'gatsby-plugin-translate'

const PageLink = ({ to, className, children }) => {
  return (
    <Location>
      {({ location }) => {
        const inAdmin = location.pathname.includes('admin')
        if (inAdmin) {
          return <a className={className}>{children}</a>
        } else {
          return (
            <TranslateLink to={to} className={className}>{children}</TranslateLink>
          )
        }
      }}
    </Location>
  )
}

PageLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default PageLink
